import clsx from "clsx"
import { OverridableComponent } from "@mui/material/OverridableComponent"
import { SvgIconTypeMap } from "@mui/material"

export type LabelWithIconProps = {
  label: string
  Icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string }
  style?: {
    mainStyle?: string
    labelStyle?: string
    iconStyle?: string
  }
}

const LabelWithIcon = ({ label, Icon, style }: LabelWithIconProps) => {
  return (
    <div
      className={clsx("text-gray-500 flex items-center", style?.mainStyle)}>
      <Icon />
      <p
        className={clsx(
          "ml-2 text-lg font-normal leading-[0px]",
          style?.labelStyle,
        )}>
        {label}
      </p>
    </div>
  )
}

export default LabelWithIcon
