export const blue = {
  50: "#F7F9FA",
  100: "#E8F4F8",
  200: "#CCE4EA",
  300: "#A8D1DC",
  400: "#7BC3D5",
  500: "#399AB3",
  600: "#2E7B8F",
  700: "#225C6B",
  800: "#173E48",
  900: "#0E282F",
}


export const gray = {
  50: "#f9fafb",
  100: "#f3f4f6",
  200: "#e5e7eb",
  300: "#d1d5db",
  400: "#9ca3af",
  500: "#6b7280",
  600: "#4b5563",
  700: "#374151",
  800: "#1f2937",
  900: "#111827",
  950: "#030712",
}

export const orange = {
  200: "#FDEDD9",
  500: "#F4921B",
}

export const green = {
  200: "#D4F7E8",
  500: "#21A871",
}

export const red = {
  200: "#FDDDE2",
  500: "#F13153",
}

export const white = "#FFFFFF"

export const black = "#000000"
