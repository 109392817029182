import { createTheme, keyframes } from "@mui/material"
import { frFR } from "@mui/material/locale"
import { black, blue, green, orange, red, white } from "./colors"

const btnFlash = keyframes`
  from {
    outline: 2px solid white;
    outline-offset: 0;
  }

  to {
    outline: 2px solid transparent;
    outline-offset: -6px;
  }
`

const theme = createTheme({
  typography: {
    fontFamily: "Roboto" || "Poppins",
  },
  palette: {
    primary: {
      contrastText: white,
      main: blue[700],
      light: blue[200],
      dark: blue[500],
    },
    secondary: {
      main: blue[500],
      contrastText: white,
    },
    error: {
      main: red[500],
      contrastText: red[200],
    },
    success: {
      main: green[500],
      contrastText: green[200],
    },
    warning: {
      main: orange[500],
      contrastText: orange[200],
    },
  },
  components: {
    // Avatar
    MuiAvatar: {
      styleOverrides: {
        root: {
          "&.md": {
            fontSize: "16px",
          },
          "&.lg": {
            fontSize: "24px",
            width: "128px",
            height: "128px",
          },
        },
      },
    },
    
    // Button
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    
    MuiButton: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          boxShadow: "none",
          letterSpacing: "initial",
          fontWeight: 500,
          fontSize: "15px",
          textTransform: "inherit",
          padding: "0.5rem 1rem",
          lineHeight: "1.5",
          borderWidth: "1px",
          "&:hover": {
            boxShadow: "none",
            borderColor: "transparent",
          },
          "&:active": {
            animationName: btnFlash,
            animationDuration: "0.5s",
          },
          "&.MuiButton-outlinedPrimary": {
            color: black,
            "&:hover": {
              color: white,
              backgroundColor: blue["500"],
            },
          },
        },
      },
    },
    
    // Chip
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: "600",
          padding: "0.2rem 1rem",
          height: "auto",
          borderRadius: "4px",
          ".MuiChip-label": {
            padding: 0,
          },
        },
      },
    },
    
    // Collapse
    MuiCollapse: {
      styleOverrides: {
        root: {
          ".MuiTableCell-body": {
            padding: "8.5px 12px",
          },
        },
      },
    },
    
    //Tab
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "inherit",
          "&.Mui-selected": {
            color: black,
          },
        },
      },
    },
    
    //Table Container
    MuiTableContainer: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderRadius: "4px",
          ".MuiTableRow-root": {},
          ".MuiTableRow-head, .MuiTableCell-stickyHeader": {
            background: blue[100],
            overflow: "initial",
          },
          ".MuiTableCell-head": {
            fontWeight: 600,
            fontSize: "14px",
          },
          ".MuiTableCell-body": {
            maxHeight: "52px",
            minHeight: "52px",
            padding: "13.75px 16px",
          },
        },
      },
    },
    
    //TextField
    MuiTextField: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
        },
      },
    },
    
    //Switch
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: "40px",
          height: "20px",
          margin: "10px",
          padding: "0",
          borderRadius: "10px",
          ".MuiSwitch-switchBase": {
            padding: "3px",
          },
          ".MuiSwitch-input": {
            width: "100%",
            height: "100%",
          },
          ".MuiSwitch-thumb": {
            width: "14px",
            height: "14px",
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiFocused": {
            borderOpacity: "0 !important",
          },
          ".MuiAutocomplete-inputRoot": {
            padding: "0 !important",
          },
        },
      },
    },
    
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontWeight: "500",
          color: black,
          display: "block",
        },
      },
    },
    
    // @ts-ignore
    // DataGrid
    MuiDataGrid: {
      styleOverrides: {
        root: {
          // border: "none !important",
          ".MuiDataGrid-cell": {
            borderBottom: `${blue[100]} 1px solid`,
            paddingTop: "1rem",
            paddingBottom: "1rem",
            color: "#707C97",
            fontSize: "0.90rem",
          },
          ".MuiDataGrid-columnHeaders": {
            background: blue[100],
            color: blue[700],
            fontSize: "1rem",
            fontWeight: 600,
          },
          ".MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
            outline: "none",
          },
          ".MuiDataGrid-virtualScrollerContent": {
            background: white,
          },
        },
      },
    },
    
    
    // DatePicker
    MuiDatePicker: {
      styleOverrides: {
        root: {},
      },
    },
  },
}, frFR)

export default theme
