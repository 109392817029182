import { Route, Routes } from "react-router-dom"
import { ClientDetails, Clients } from "constants/lazyLoad/lazyClients"

const ClientsRoutes = () => {
  
  return (
    <Routes>
      <Route index element={<Clients />} />
      <Route path=":id/*" element={<ClientDetails />} />
    </Routes>
  )
}

export default ClientsRoutes
