import { BrowserRouter as Router } from "react-router-dom"
import Header from "layout/main/header/Header"
import AppRoutes from "routes/appRoutes"
import Sidebar from "layout/main/sidebar/Sidebar"
import { ThemeProvider } from "@mui/material/styles"
import theme from "themes/theme"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import React from "react"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <div className="">
          <Router>
            <Header />
            {/*<ProtectedRoute>*/}
            <Sidebar />
            <div className="lg:ml-[20%]">
              <div className="bg-gray-100">
                <div className="container mx-auto min-h-screen py-12 space-y-6">
                  <AppRoutes />
                </div>
              </div>
            </div>
            {/*</ProtectedRoute>*/}
          </Router>
        </div>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default App
