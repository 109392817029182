export type SettingProps = {}

const Setting = (props: SettingProps) => {
  return (
    <div>
      <h1>Setting</h1>
    </div>
  )
}

export default Setting
