import "./Sidebar.scss"
import LinkWithIcon from "components/common/linkWithIcon/LinkWithIcon"
import { NavigationItemList } from "constants/NavigationItemList"
import { useLocation } from "react-router-dom"
import { isPathCurrentRoute } from "helpers/navigation"
import clsx from "clsx"
import { useContext } from "react"
import { SidebarResponsiveContext, SidebarResponsiveContextValueType } from "context/SidebarResponsiveContext"
import { useKeycloak } from "@react-keycloak/web"
import { CloseRounded, LogoutRounded } from "@mui/icons-material"

type SidebarProps = {}

const Sidebar = (props: SidebarProps) => {
  const location = useLocation()
  const { isExpanded, expand } = useContext(
    SidebarResponsiveContext,
  ) as SidebarResponsiveContextValueType
  
  const { keycloak } = useKeycloak()
  
  const DrawerWithLink = () => (
    <>
      <div className="flex items-center justify-between mb-20 px-5">
        <h1 className="font-medium text-base lg:text-xl text-blue-700">
          Uptimise | Gestionnaire
        </h1>
        <CloseRounded
          color="primary"
          onClick={expand}
          className="lg:hidden"
        />
      </div>
      {NavigationItemList.map((menuItem, index) => {
        const isActive = isPathCurrentRoute(location, menuItem.link)
        return (
          <div
            key={index}
            className={clsx(
              "pl-6 py-3",
              isActive
                ? "bg-blue-200 menu-border-isActive pl-5 font-medium"
                : "",
            )}>
            <LinkWithIcon
              linkProps={{
                to: menuItem.link,
              }}
              label={menuItem.label}
              Icon={menuItem.Icon}
              style={{
                mainStyle: isActive ? "up-sidebar-link-active" : "",
                /*  labelStyle: isExpanded ? "" : "hidden lg:block", */
              }}
            />
          </div>
        )
      })}
      <div
        onClick={() => keycloak.logout()}
        className="cursor-pointer pl-6 py-3 text-gray-500 flex items-center">
        <LogoutRounded />
        <p className="ml-2 text-lg font-normal leading-[0px]">Se déconnecter</p>
      </div>
    </>
  )
  
  return (
    <>
      {isExpanded && (
        <div className="lg:hidden fixed bg-white w-[60%] h-full z-50 m-0 top-0 py-4 overflow-auto">
          <DrawerWithLink />
        </div>
      )}
      <div className="hidden lg:block fixed bg-white  w-[20%] h-full m-0 py-3 overflow-auto">
        <DrawerWithLink />
      </div>
    </>
  )
}

/* const Sidebar = (props: SidebarProps) => {
  const location = useLocation()
  const { isExpanded, expand } = useContext(
    SidebarResponsiveContext
  ) as SidebarResponsiveContextValueType

  const DrawerWithLink = () => (
    <div className=" w-full">
      <h1 className="font-medium text-xs lg:text-xl text-blue-700 mb-20 pl-5">
        Uptimise | Gestionnaire
      </h1>
      {NavigationItemList.map((menuItem, index) => {
        const isActive = isPathCurrentRoute(location, menuItem.link)
        return (
          <div
            key={index}
            className={clsx(
              "pl-6 py-3",
              isActive
                ? "bg-blue-200 menu-border-isActive pl-5 font-medium"
                : ""
            )}>
            <LinkWithIcon
              to={menuItem.link}
              label={menuItem.label}
              Icon={menuItem.Icon}
              style={{
                mainStyle: isActive ? "up-sidebar-link-active" : "",
                
              }}
            />
          </div>
        )
      })}
    </div>
  )

  return (
    <Box
      
      sx={{ width: { sm: DRAWER_WIDTH }}}
      aria-label="mailbox folders">
      <Drawer
        
        variant="temporary"
        open={isExpanded}
        onClose={expand}
        
        sx={{
          display: { xs: "flex", sm: "none" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
          },
        }}>
        <DrawerWithLink />
      </Drawer>
      <Drawer
        variant="permanent"
        elevation={0}
        sx={{
          display: { xs: "none", sm: "flex" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: DRAWER_WIDTH,
            borderColor: "#fff",
          },
        }}
        open>
        <DrawerWithLink />
      </Drawer>
    </Box>
  )
} */

export default Sidebar
