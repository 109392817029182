import { SupportMemberType } from "../types/SupportMembersType"

export const formatMoney = (amount: number, format = true) => {
  let formattedNumber = amount
  let parts
  if (format) {
    formattedNumber = Math.round(amount ?? 0)
  }
  parts = (formattedNumber ?? 0).toString().split(".")
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ")

  return parts.join(".")
}


export const getUserFullName = (user: SupportMemberType | undefined, reverse = false, twoLetter = false) => {
  if (!user) return ""
  let last_name = twoLetter ? user?.last_name.split(" ")[0] : user?.last_name
  let first_name = twoLetter ? user?.first_name.split(" ")[0] : user?.first_name
  if (reverse)
    return `${last_name} ${first_name}`
  return `${first_name} ${last_name}`
}

export const generateRandomString = (initials?: string, length: number = 8, charSet?: string) => {
  charSet = charSet || "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789"
  let result = initials ? `${initials}_` : ""
  for (let i = 0; i < length; i++) {
    result += charSet.charAt(Math.floor(Math.random() * charSet.length))
  }
  return result
}


export const cleanObject = (obj: { [key: string]: any }) => {
  Object.keys(obj).forEach(key => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key]
    }
  })
  return obj
}