import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  GET_ADD_SUPPORT_MEMBERS_URL,
  GET_UPDATE_DELETE_SUPPORT_MEMBERS_URL,
  INVITE_SUPPORT_MEMBER_IN_COMPANY_URL,
} from "../../constants/api/endpoints"
import { http } from "../../utils/http"
import { AxiosResponse } from "axios"
import { APIPaginatedResponseType, APIPaginationType } from "../../types/api/utils"
import {
  InviteSupportInCompanyFormType,
  SupportMemberFormType,
  SupportMemberType,
} from "../../types/SupportMembersType"

// Fetch all supports members
export const fetchSupportMembers = createAsyncThunk<APIPaginatedResponseType<SupportMemberType> | SupportMemberType[], { pageIndex?: number }>(
  "supportMembers/fetchSupportMembers",
  async ({ pageIndex }, thunkApi) => {

    const url = pageIndex ? `${GET_ADD_SUPPORT_MEMBERS_URL}?page=${pageIndex}` : GET_ADD_SUPPORT_MEMBERS_URL
    return http
      .get<APIPaginatedResponseType<SupportMemberType> | SupportMemberType[]>(url)
      .then((res): APIPaginatedResponseType<SupportMemberType> | SupportMemberType[] => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  })


// Create a support member
export const createAsyncSupportMember = createAsyncThunk<SupportMemberType, SupportMemberFormType>(
  "supportMembers/createAsyncSupportMembers",
  async (supportMember: Partial<SupportMemberFormType>, thunkApi) => {
    return http
      .post<SupportMemberType>(GET_ADD_SUPPORT_MEMBERS_URL, supportMember)
      .then((res): SupportMemberType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Invite a support user in one of the customers company a support member
export const asyncInviteSupportMemberInCompany = createAsyncThunk<SupportMemberType, InviteSupportInCompanyFormType>(
  "supportMembers/asyncInviteSupportMemberInCompany",
  async ({ support_member, company }: InviteSupportInCompanyFormType, thunkApi) => {
    return http
      .post<SupportMemberType>(INVITE_SUPPORT_MEMBER_IN_COMPANY_URL.replace(":id", `${support_member}`), { company })
      .then((res): SupportMemberType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Update an existing support member
export const asyncUpdateSupportMember = createAsyncThunk<SupportMemberType, {
  id?: string,
  data?: Partial<SupportMemberFormType | FormData>,
}>(
  "supportMembers/asyncUpdateSupportMembers",
  async ({ id, data }: {
    id?: string,
    data?: Partial<SupportMemberFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<SupportMemberType>(GET_UPDATE_DELETE_SUPPORT_MEMBERS_URL.replace(":id", `${id}`), data)
      .then((res): SupportMemberType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Delete an existing support member
export const deleteAsyncSupportMember = createAsyncThunk<AxiosResponse, { id: string }>(
  "supportMembers/deleteAsyncSupportMember",
  async ({ id }: { id: string }, thunkApi) => {
    return http
      .delete<AxiosResponse>(GET_UPDATE_DELETE_SUPPORT_MEMBERS_URL.replace(":id", id))
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)


// initial state
const initialState: {
  supportMembers: SupportMemberType[],
  pagination?: APIPaginationType
  supportMembersStatus: string,
  supportMembersErrors?: any,

  addSupportMemberStatus: string,
  addSupportMemberErrors?: any,

  deleteSupportMemberErrors?: any,
  deleteSupportMemberStatus: string,
} = {
  supportMembers: [],
  supportMembersStatus: "idle",
  addSupportMemberStatus: "idle",
  deleteSupportMemberStatus: "idle",
}

const supportMembersSlice = createSlice({
  name: "supportMembers",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all support members
    builder
      .addCase(fetchSupportMembers.pending, (state) => {
        state.supportMembersStatus = "loading"
      })
      .addCase(fetchSupportMembers.fulfilled, (state, action) => {
        state.supportMembersStatus = "succeeded"
        if ("results" in action.payload) {
          const { results, ...pagination } = action.payload
          state.supportMembers = results
          state.pagination = pagination
        } else {
          state.supportMembers = action.payload
        }
      })
      .addCase(fetchSupportMembers.rejected, (state, action) => {
        state.supportMembersStatus = "failed"
        state.supportMembersErrors = action.error
      })

    builder
      .addCase(createAsyncSupportMember.pending, (state) => {
        state.addSupportMemberStatus = "loading"
      })
      .addCase(createAsyncSupportMember.fulfilled, (state, action) => {
        state.addSupportMemberStatus = "succeeded"
        state.supportMembers = [...state.supportMembers, action.payload]

      })
      .addCase(createAsyncSupportMember.rejected, (state, action) => {
        state.addSupportMemberStatus = "failed"
        state.addSupportMemberErrors = action.error
      })

    builder
      .addCase(asyncUpdateSupportMember.pending, (state) => {
        state.supportMembersStatus = "loading"
      })
      .addCase(asyncUpdateSupportMember.fulfilled, (state, action) => {
        state.supportMembersStatus = "succeeded"
        let index = state.supportMembers.findIndex(supportMember => supportMember.id === action.meta.arg.id)
        if (index >= 0) {
          state.supportMembers.splice(index, 1)
          state.supportMembers.unshift(action.payload)
        }
      })
      .addCase(asyncUpdateSupportMember.rejected, (state, action) => {
        state.supportMembersStatus = "failed"
        state.supportMembersErrors = action.error
      })

    builder
      .addCase(asyncInviteSupportMemberInCompany.pending, (state) => {
        state.supportMembersStatus = "loading"
      })
      .addCase(asyncInviteSupportMemberInCompany.fulfilled, (state, action) => {
        state.supportMembersStatus = "succeeded"
        let index = state.supportMembers.findIndex(supportMember => supportMember.id === action.meta.arg.support_member)
        console.log(action.meta.arg.support_member)
        console.log(action.payload)
        if (index >= 0) {
          state.supportMembers.splice(index, 1)
          state.supportMembers.unshift(action.payload)
        }
      })
      .addCase(asyncInviteSupportMemberInCompany.rejected, (state, action) => {
        state.supportMembersStatus = "failed"
        state.supportMembersErrors = action.error
      })

    builder
      .addCase(deleteAsyncSupportMember.pending, (state) => {
        state.deleteSupportMemberStatus = "loading"
      })
      .addCase(deleteAsyncSupportMember.fulfilled, (state, action) => {
        state.supportMembersStatus = "succeeded"
        state.supportMembers = state.supportMembers.filter((supportMember) => supportMember.id !== action.meta.arg.id)
      })
      .addCase(deleteAsyncSupportMember.rejected, (state, action) => {
        state.deleteSupportMemberStatus = "failed"
        state.deleteSupportMemberErrors = action.error
      })

  },
})

export default supportMembersSlice.reducer

