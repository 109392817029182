import { configureStore } from "@reduxjs/toolkit"
import plansReducer from "./redux/features/plansSlice"
import companiesReducer from "./redux/features/companiesSlice"
import subscriptionsReducer from "./redux/features/subscriptionsSlice"
import paymentsReducer from "./redux/features/paymentsSlice"
import supportMembersSlice from "./redux/features/supportMembersSlice"

export const store = configureStore({
  reducer: {
    plans: plansReducer,
    companies: companiesReducer,
    subscriptions: subscriptionsReducer,
    payments: paymentsReducer,
    supportMembers: supportMembersSlice,
  },
  // devTools: ENVIRONMENT === "development",
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

