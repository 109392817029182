import { NavigationItemType } from "types/NavigationItem"
import {
  BarChartOutlined,
  DashboardOutlined,
  DoneAllOutlined,
  PointOfSaleOutlined,
  ReceiptLong,
  ReceiptOutlined,
  RequestQuoteOutlined,
  SettingsOutlined,
  SupportAgentOutlined,
  SwitchAccountOutlined,
} from "@mui/icons-material"

export const NavigationItemList: NavigationItemType[] = [
  {
    label: "Tableau de board",
    link: "/",
    Icon: DashboardOutlined,
  },
  {
    label: "Clients",
    link: "/clients",
    Icon: SwitchAccountOutlined,
  },
  {
    label: "Offres",
    link: "/offres",
    Icon: ReceiptOutlined,
  },
  {
    label: "Paiement",
    link: "/paiements",
    Icon: RequestQuoteOutlined,
  },
  {
    label: "Factures",
    link: "/factures",
    Icon: ReceiptLong,
  },

  {
    label: "Activités",
    link: "/activites",
    Icon: PointOfSaleOutlined,
  },
  {
    label: "Support",
    link: "/membres-du-support",
    Icon: SupportAgentOutlined,
  },
  {
    label: "Affectations Closes",
    link: "/affectations-closes",
    Icon: DoneAllOutlined,
  },

  {
    label: "Rapports",
    link: "/rapports-statistiques",
    Icon: BarChartOutlined,
  },

  {
    label: "Paramètres",
    link: "/parametres",
    Icon: SettingsOutlined,
  } /* 
  {
    label: "Se déconnecter",
    link: "/logout",
    Icon: LogoutRounded,
  }, */,
]
