import React from "react"

const NotFoundPage = () => {
  return (
    <div className="text-center">
      <h1 className="font-semibold mb-16 text-blue-700">
        404 - Page Not Found
      </h1>
      <h3>Désolé la page recherchée n'existe pas 😒</h3>
      <p>Assurez vous d'être connecté et d'être sur un lien valide.</p>
    </div>
  )
}

export default NotFoundPage
