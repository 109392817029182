import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { PlanType } from "../../types/PlansType"
import { GET_ADD_PLANS_URL, GET_UPDATE_DELETE_PLAN_URL } from "../../constants/api/endpoints"
import { http } from "../../utils/http"
import { AxiosResponse } from "axios"
import { PlanFormType } from "../../types/api/plan"


// Todo review the response from the update an the create function

// Fetch all plans
export const fetchPlans = createAsyncThunk("plan/fetchPlans", async (_, thunkApi) => {
  return http
    .get<PlanType[]>(GET_ADD_PLANS_URL)
    .then((res): PlanType[] => res.data)
    .catch(e => {
      return thunkApi.rejectWithValue({ data: e.data })
    })
})

// Create a plan
export const createAsyncPlan = createAsyncThunk<PlanType, PlanFormType>(
  "plan/createAsyncPlan",
  async (plan: Partial<PlanFormType>, thunkApi) => {
    return http
      .post<PlanType>(GET_ADD_PLANS_URL, plan)
      .then((res): PlanType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Update an existing plan
export const updateAsyncPlan = createAsyncThunk<PlanType, {
  id?: string,
  data?: Partial<PlanFormType | FormData>,
}>(
  "plan/updateAsyncPlan",
  async ({ id, data }: {
    id?: string,
    data?: Partial<PlanFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<PlanType>(GET_UPDATE_DELETE_PLAN_URL.replace(":id", `${id}`), data)
      .then((res): PlanType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Delete an existing plan
export const deleteAsyncPlan = createAsyncThunk<AxiosResponse, { id: string }>(
  "plan/deleteAsyncPlan",
  async ({ id }: { id: string }, thunkApi) => {
    return http
      .delete<AxiosResponse>(GET_UPDATE_DELETE_PLAN_URL.replace(":id", id))
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)


// initial state
const initialState: {
  plans: PlanType[],
  plansStatus: string,
  plansErrors?: any,

  addPlanStatus: string,
  addPlanErrors?: any,

  deletePlanErrors?: any,
  deletePlanStatus: string,
} = {
  plans: [],
  plansStatus: "idle",
  addPlanStatus: "idle",
  deletePlanStatus: "idle",
}

const plansSlice = createSlice({
  name: "plans",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all plans
    builder
      .addCase(fetchPlans.pending, (state) => {
        state.plansStatus = "loading"
      })
      .addCase(fetchPlans.fulfilled, (state, action) => {
        state.plansStatus = "succeeded"
        state.plans = action.payload
      })
      .addCase(fetchPlans.rejected, (state, action) => {
        state.plansStatus = "failed"
        state.plansErrors = action.error
      })

    builder
      .addCase(createAsyncPlan.pending, (state) => {
        state.addPlanStatus = "loading"
      })
      .addCase(createAsyncPlan.fulfilled, (state, action) => {
        state.addPlanStatus = "succeeded"
        state.plans = [...state.plans, action.payload]

      })
      .addCase(createAsyncPlan.rejected, (state, action) => {
        state.addPlanStatus = "failed"
        state.addPlanErrors = action.error
      })

    builder
      .addCase(updateAsyncPlan.pending, (state) => {
        state.plansStatus = "loading"
      })
      .addCase(updateAsyncPlan.fulfilled, (state, action) => {
        state.plansStatus = "succeeded"
        let index = state.plans.findIndex(plan => plan.id === action.meta.arg.id)
        if (index >= 0) {
          state.plans.splice(index, 1)
          state.plans.reverse()
          state.plans.unshift(action.payload)
          state.plans.reverse()
        }

      })
      .addCase(updateAsyncPlan.rejected, (state, action) => {
        state.plansStatus = "failed"
        state.plansErrors = action.error
      })

    builder
      .addCase(deleteAsyncPlan.pending, (state) => {
        state.deletePlanStatus = "loading"
      })
      .addCase(deleteAsyncPlan.fulfilled, (state, action) => {
        state.plansStatus = "succeeded"
        state.plans = state.plans.filter((plan) => plan.id !== action.meta.arg.id)
      })
      .addCase(deleteAsyncPlan.rejected, (state, action) => {
        state.deletePlanStatus = "failed"
        state.deletePlanErrors = action.error
      })

  },
})

export default plansSlice.reducer

