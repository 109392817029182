export type InvoicesProps = {}

const Invoices = (props: InvoicesProps) => {
  return (
    <div>
      <h1>Invoices</h1>
    </div>
  )
}

export default Invoices
