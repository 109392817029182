export type ActivityProps = {}

const Activity = (props: ActivityProps) => {
  return (
    <>
      <h1>Activity</h1>
    </>
  )
}

export default Activity
