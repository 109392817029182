export type ClosedAssignmentProps = {}

const ClosedAssignment = (props: ClosedAssignmentProps) => {
  return (
    <div>
      <h1>Closed Assignment</h1>
    </div>
  )
}

export default ClosedAssignment
