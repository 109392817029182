import { Link } from "react-router-dom"
import { useContext, useEffect, useState } from "react"
import { SidebarResponsiveContext, SidebarResponsiveContextValueType } from "context/SidebarResponsiveContext"
import { useKeycloak } from "@react-keycloak/web"
import { KeyboardArrowDownRounded, MenuRounded, NotificationsRounded } from "@mui/icons-material"
import { fetchPlans } from "../../../redux/features/plansSlice"
import { useAppDispatch } from "../../../hooks/reduxToolkitHook"

const Header = () => {
  const [userInfo, setUserInfo] = useState<{} | any>({})

  const { isExpanded, expand } = useContext(
    SidebarResponsiveContext,
  ) as SidebarResponsiveContextValueType

  const { keycloak } = useKeycloak()
  const dispatch = useAppDispatch()

  useEffect(() => {
    keycloak
      .loadUserInfo()
      .then((user) => {
        setUserInfo(user)
        // console.log(user)
      })
      .catch((error) => console.log(error))

    dispatch(fetchPlans())
  }, [])

  {
    /* <div className={`fixed bg-red-300 col-span-8`}> */
  }
  return (
    <div className="sticky top-0 z-20 w-full bg-white py-4 px-9 flex justify-between items-center shadow-sm">
      <MenuRounded
        color="primary"
        onClick={expand}
        className="lg:hidden"
      />
      <Link to="/" className="no-underline">
        <h1 className="font-medium text-xl lg:hidden text-blue-700">
          Uptimise | Gestionnaire
        </h1>
      </Link>
      <div className="flex items-center">
        <NotificationsRounded color="primary" />
        <div className="w-8 h-8 rounded-full flex items-center justify-center ml-4">
          <img
            alt="user avatar"
            src="https://randomuser.me/api/portraits/med/men/75.jpg"
            width="30px"
            className="object-contain rounded-full"
          />
        </div>
        <p className="text-lg ml-4 text-blue-700 hidden lg:block">
          {userInfo?.preferred_username}
        </p>
        <KeyboardArrowDownRounded color="primary" className="ml-4" />
      </div>
    </div>
  )
  /* </div> */
}

/* const Header = () => {
  const { isExpanded, expand } = useContext(
    SidebarResponsiveContext
  ) as SidebarResponsiveContextValueType

  return (
    <div
      className={`fixed bg-white z-50 w-full h-[${HEADER_HEIGTH}px]   md:w-[calc(100%-${DRAWER_WIDTH}px)]`}>
      <div className="py-4 px-9 flex justify-between items-center shadow-sm">
        <MdOutlineMenu
          size="24px"
          color="#399AB3"
          onClick={expand}
          className="sm:hidden"
        />
        <Link to="/" className="no-underline">
          <h1 className="font-medium text-xl lg:hidden text-blue-700">
            Uptimise | Gestionnaire
          </h1>
        </Link>
        <div className="flex items-center">
          <MdNotifications size="24px" className="text-blue-700" />
          <div className="w-8 h-8 rounded-full flex items-center justify-center ml-4">
            <img
              alt="user avatar"
              src="https://randomuser.me/api/portraits/med/men/75.jpg"
              width="30px"
              
              className="object-contain rounded-full"
            />
          </div>
          <p className="text-lg ml-4 text-blue-700 hidden lg:block">
            Jean-Eude
          </p>
          <MdOutlineKeyboardArrowDown
            size="24px"
            className="ml-4 text-blue-700"
          />
        </div>
      </div>
    </div>
  )
}
 */
export default Header
