import { Navigate } from "react-router-dom"

const Logout = () => {
  return (
    <div className="text-center">
      <h1 className="font-semibold mb-16 text-blue-700">Déconnexion</h1>
      <h3>Déconnexion en cours 😒</h3>
      <h2>Cliquez ici si la déconnexion n'a pas eu lieu</h2>
      <Navigate replace to="/" />
    </div>
  )
}

export default Logout
