export type StatisticalReportProps = {}

const StatisticalReport = (props: StatisticalReportProps) => {
  return (
    <div>
      <h1>StatisticalReport</h1>
    </div>
  )
}

export default StatisticalReport
