import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { GET_ADD_PAYMENTS_URL, GET_UPDATE_DELETE_PAYMENT_URL } from "../../constants/api/endpoints"
import { http } from "../../utils/http"
import { AxiosResponse } from "axios"
import { APIPaginatedResponseType, APIPaginationType } from "../../types/api/utils"
import { PaymentFormType, PaymentType } from "../../types/PaymentsType"

// Fetch all payments
export const fetchPayments = createAsyncThunk<APIPaginatedResponseType<PaymentType> | PaymentType[], { pageIndex?: number }>(
  "payment/fetchPayments",
  async ({ pageIndex }, thunkApi) => {

    const url = pageIndex ? `${GET_ADD_PAYMENTS_URL}?page=${pageIndex}` : GET_ADD_PAYMENTS_URL
    return http
      .get<APIPaginatedResponseType<PaymentType> | PaymentType[]>(url)
      .then((res): APIPaginatedResponseType<PaymentType> | PaymentType[] => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  })


// Create a payment
export const createAsyncPayment = createAsyncThunk<PaymentType, PaymentFormType>(
  "payment/createAsyncPayment",
  async (payment: Partial<PaymentFormType>, thunkApi) => {
    return http
      .post<PaymentType>(GET_ADD_PAYMENTS_URL, payment)
      .then((res): PaymentType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Update an existing payment
export const asyncUpdatePayment = createAsyncThunk<PaymentType, {
  id?: string,
  data?: Partial<PaymentFormType | FormData>,
}>(
  "payment/asyncUpdatePayment",
  async ({ id, data }: {
    id?: string,
    data?: Partial<PaymentFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<PaymentType>(GET_UPDATE_DELETE_PAYMENT_URL.replace(":id", `${id}`), data)
      .then((res): PaymentType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Delete an existing payment
export const deleteAsyncPayment = createAsyncThunk<AxiosResponse, { id: string }>(
  "payment/deleteAsyncPayment",
  async ({ id }: { id: string }, thunkApi) => {
    return http
      .delete<AxiosResponse>(GET_UPDATE_DELETE_PAYMENT_URL.replace(":id", id))
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)


// initial state
const initialState: {
  payments: PaymentType[],
  pagination?: APIPaginationType
  paymentsStatus: string,
  paymentsErrors?: any,

  addPaymentStatus: string,
  addPaymentErrors?: any,

  deletePaymentErrors?: any,
  deletePaymentStatus: string,
} = {
  payments: [],
  paymentsStatus: "idle",
  addPaymentStatus: "idle",
  deletePaymentStatus: "idle",
}

const paymentsSlice = createSlice({
  name: "payments",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all payments
    builder
      .addCase(fetchPayments.pending, (state) => {
        state.paymentsStatus = "loading"
      })
      .addCase(fetchPayments.fulfilled, (state, action) => {
        state.paymentsStatus = "succeeded"
        if ("results" in action.payload) {
          const { results, ...pagination } = action.payload
          state.payments = results
          state.pagination = pagination
        } else {
          state.payments = action.payload
        }
      })
      .addCase(fetchPayments.rejected, (state, action) => {
        state.paymentsStatus = "failed"
        state.paymentsErrors = action.error
      })

    builder
      .addCase(createAsyncPayment.pending, (state) => {
        state.addPaymentStatus = "loading"
      })
      .addCase(createAsyncPayment.fulfilled, (state, action) => {
        state.addPaymentStatus = "succeeded"
        state.payments = [...state.payments, action.payload]

      })
      .addCase(createAsyncPayment.rejected, (state, action) => {
        state.addPaymentStatus = "failed"
        state.addPaymentErrors = action.error
      })

    builder
      .addCase(asyncUpdatePayment.pending, (state) => {
        state.paymentsStatus = "loading"
      })
      .addCase(asyncUpdatePayment.fulfilled, (state, action) => {
        state.paymentsStatus = "succeeded"
        let index = state.payments.findIndex(payment => payment.id === action.meta.arg.id)
        if (index >= 0) {
          state.payments.splice(index, 1)
          state.payments.unshift(action.payload)
        }

      })
      .addCase(asyncUpdatePayment.rejected, (state, action) => {
        state.paymentsStatus = "failed"
        state.paymentsErrors = action.error
      })

    builder
      .addCase(deleteAsyncPayment.pending, (state) => {
        state.deletePaymentStatus = "loading"
      })
      .addCase(deleteAsyncPayment.fulfilled, (state, action) => {
        state.paymentsStatus = "succeeded"
        state.payments = state.payments.filter((payment) => payment.id !== action.meta.arg.id)
      })
      .addCase(deleteAsyncPayment.rejected, (state, action) => {
        state.deletePaymentStatus = "failed"
        state.deletePaymentErrors = action.error
      })

  },
})

export default paymentsSlice.reducer

