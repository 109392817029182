import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {
  GET_ADD_COMPANY_SUBSCRIPTION_URL,
  GET_CREATE_COMPANIES_URL,
  GET_UPDATE_DELETE_PLAN_URL,
} from "../../constants/api/endpoints"
import { http } from "../../utils/http"
import { AxiosResponse } from "axios"
import { CompanyType } from "../../types/CompaniesType"
import { APIPaginatedResponseType, APIPaginationType, ExtraRequestParams } from "../../types/api/utils"
import { PricingFormType } from "../../types/PricingForm"
import { cleanObject } from "../../utils/utils"

// Fetch all companies
export const fetchCompanies = createAsyncThunk<APIPaginatedResponseType<CompanyType> | CompanyType[], ExtraRequestParams>(
  "company/fetchCompanies",
  async (extraParams, thunkApi) => {
    const params = new URLSearchParams(extraParams ? cleanObject(extraParams) : {})
    const url = params.toString() ? `${GET_CREATE_COMPANIES_URL}?${params.toString()}` : `${GET_CREATE_COMPANIES_URL}`
    // console.log(url)
    return http
      .get<APIPaginatedResponseType<CompanyType> | CompanyType[]>(url)
      .then((res): APIPaginatedResponseType<CompanyType> | CompanyType[] => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  })
/*
// Create a company
export const createAsyncCompany = createAsyncThunk<CompanyType, PlanFormType>(
  "company/createAsyncCompany",
  async (plan: Partial<PlanFormType>, thunkApi) => {
    return http
      .post<CompanyType>(GET_ADD_PLANS_URL, plan)
      .then((res): CompanyType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)*/
/*

// Update an existing company
export const updateAsyncCompany = createAsyncThunk<CompanyType, {
  id?: string,
  data?: Partial<PlanFormType | FormData>,
}>(
  "company/updateAsyncCompany",
  async ({ id, data }: {
    id?: string,
    data?: Partial<PlanFormType | FormData>,
  }, thunkApi) => {
    return http
      .patch<CompanyType>(GET_UPDATE_DELETE_PLAN_URL.replace(":id", `${id}`), data)
      .then((res): CompanyType => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)
*/


// Update an existing company
export const asyncAddSubscriptionToCompany = createAsyncThunk<any, {
  companyId: string,
  data?: Partial<PricingFormType | FormData>,
}>(
  "company/asyncAddSubscriptionToCompany",
  async ({ companyId, data }: {
    companyId: string,
    data?: Partial<PricingFormType | FormData>,
  }, thunkApi) => {
    return http
      .post(GET_ADD_COMPANY_SUBSCRIPTION_URL.replace(":id", `${companyId}`), data)
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)

// Delete an existing company
export const deleteAsyncCompany = createAsyncThunk<AxiosResponse, { id: string }>(
  "company/deleteAsyncCompany",
  async ({ id }: { id: string }, thunkApi) => {
    return http
      .delete<AxiosResponse>(GET_UPDATE_DELETE_PLAN_URL.replace(":id", id))
      .then(res => res.data)
      .catch(e => {
        return thunkApi.rejectWithValue({ data: e.data })
      })
  },
)


// initial state
const initialState: {
  companies: CompanyType[],
  pagination?: APIPaginationType
  companiesStatus: string,
  companiesErrors?: any,

  addCompanyStatus: string,
  addCompanyErrors?: any,

  deleteCompanyErrors?: any,
  deleteCompanyStatus: string,
} = {
  companies: [],
  companiesStatus: "idle",
  addCompanyStatus: "idle",
  deleteCompanyStatus: "idle",
}

const companiesSlice = createSlice({
  name: "companies",
  initialState,
  reducers: {},

  extraReducers(builder) {
    // get  all companies
    // Todo handle pagination
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.companiesStatus = "loading"
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.companiesStatus = "succeeded"
        if ("results" in action.payload) {
          const { results, ...pagination } = action.payload
          state.companies = results
          state.pagination = pagination
        } else {
          state.companies = action.payload
        }
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.companiesStatus = "failed"
        state.companiesErrors = action.error
      })

    /*builder
      .addCase(createAsyncCompany.pending, (state) => {
        state.addCompanyStatus = "loading"
      })
      .addCase(createAsyncCompany.fulfilled, (state, action) => {
        state.addCompanyStatus = "succeeded"
        // state.companies = [...state.companies, action.payload]
        // Todo: automatically fetch companies list

      })
      .addCase(createAsyncCompany.rejected, (state, action) => {
        state.addCompanyStatus = "failed"
        state.addCompanyErrors = action.error
      })

    builder
      .addCase(updateAsyncCompany.pending, (state) => {
        state.companiesStatus = "loading"
      })
      .addCase(updateAsyncCompany.fulfilled, (state, action) => {
        state.companiesStatus = "succeeded"
        let index = state.companies.findIndex(company => company.id === action.meta.arg.id)
        if (index >= 0) {
          state.companies.splice(index, 1)
          state.companies.reverse()
          // Todo Find the updated company and update it or make a global fetch
          state.companies.unshift(action.payload)
          state.companies.reverse()
        }

      })
      .addCase(updateAsyncCompany.rejected, (state, action) => {
        state.companiesStatus = "failed"
        state.companiesErrors = action.error
      })*/

    builder
      .addCase(asyncAddSubscriptionToCompany.pending, (state) => {
        state.companiesStatus = "loading"
      })
      .addCase(asyncAddSubscriptionToCompany.fulfilled, (state, action) => {
        state.companiesStatus = "succeeded"
      })
      .addCase(asyncAddSubscriptionToCompany.rejected, (state, action) => {
        state.companiesStatus = "failed"
        state.companiesErrors = action.error
      })

    builder
      .addCase(deleteAsyncCompany.pending, (state) => {
        state.deleteCompanyStatus = "loading"
      })
      .addCase(deleteAsyncCompany.fulfilled, (state, action) => {
        state.companiesStatus = "succeeded"
        state.companies = state.companies.filter((company) => company.id !== action.meta.arg.id)
      })
      .addCase(deleteAsyncCompany.rejected, (state, action) => {
        state.deleteCompanyStatus = "failed"
        state.deleteCompanyErrors = action.error
      })

  },
})

export default companiesSlice.reducer

