import { Route, Routes } from "react-router-dom"
import NotFoundPage from "views/404/NotFoundPage"
import Activity from "views/activity/Activity"
import ClosedAssignment from "views/assignment/ClosedAssignment"
import Logout from "views/auth/Logout"
import Home from "views/home/Home"
import Invoices from "views/invoices/Invoices"
import StatisticalReport from "views/report/StatisticalReport"
import Setting from "views/setting/Setting"
import { Dashboard } from "constants/lazyLoad/lazyMain"
import { Suspense } from "react"
import { CircularProgress } from "@mui/material"
import ClientsRoutes from "./ClientsRoutes"
import { AddClient } from "../constants/lazyLoad/lazyClients"
import { Offers } from "../constants/lazyLoad/lazyOffers"
import { Payments } from "../constants/lazyLoad/lazyPayments"
import { SupportMembers } from "../constants/lazyLoad/lazySupportMembers"

const AppRoutes = () => {
  return (
    <Suspense fallback={<div className="min-h-scree flex justify-center items-center"><CircularProgress /></div>}>
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Dashboard />} />
          <Route path="ajout-client/*" element={<AddClient />} />
          <Route path="clients/*" element={<ClientsRoutes />} />
          <Route path="/offres" element={<Offers />} />
          <Route path="/paiements" element={<Payments />} />
          <Route path="/membres-du-support" element={<SupportMembers />} />
          <Route path="/factures" element={<Invoices />} />
          <Route path="/activites" element={<Activity />} />
          <Route path="/affectations-closes" element={<ClosedAssignment />} />
          <Route path="/rapports-statistiques" element={<StatisticalReport />} />
          <Route path="/parametres" element={<Setting />} />
          <Route path="/logout" element={<Logout />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    </Suspense>
  )
}

export default AppRoutes
